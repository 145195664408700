import { useQuery, UseQueryResult } from 'react-query';

import { usePropsContext, useToastContext } from 'apps/website/coaching/scheduling_widget/contexts';

import { SchedulingWidgetConfigResponse } from 'apps/website/coaching/scheduling_widget/SchedulingWidget.types';
import fetchSchedulingWidgetConfig from '../api/fetchSchedulingWidgetConfig';

import * as QUERY_KEYS from './query-keys';

function useSchedulingWidgetConfig(): UseQueryResult<SchedulingWidgetConfigResponse, Error> {
  const { configRoute, coachingProgramId, previewing } = usePropsContext();

  return useQuery(
    [QUERY_KEYS.SCHEDULING_WIDGET_CONFIG, coachingProgramId],
    () =>
      fetchSchedulingWidgetConfig({
        url: configRoute!,
        previewing,
      }),
    {
      enabled: !!coachingProgramId || !!configRoute,
    },
  );
}

export default useSchedulingWidgetConfig;
