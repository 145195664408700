import React from 'react';

function reactElementFromHtmlElement(domElement: HTMLElement): JSX.Element {
  const props: Record<string, any> = {};

  // Extract attributes and convert to plain object
  Array.from(domElement.attributes).forEach((attr) => {
    if (attr.name === 'class') return;
    if (attr.name === 'style') return;
    props[attr.name] = attr.value;
  });

  props.className = domElement.className;

  return React.createElement(domElement.tagName.toLowerCase(), props, domElement.textContent);
}

export default reactElementFromHtmlElement;
