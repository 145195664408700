import React from 'react';
import { PdsBox, PdsRow } from '@pine-ds/react';

import usePopupCheckoutCallbacks from './hooks/usePopupCheckoutCallbacks';
import useScrollBlocker from './hooks/useScrollBlocker';
import TriggerElement from './components/TriggerElement';
import SchedulingModal from './components/SchedulingModal';
import CoachingProgramShow from './components/CoachingProgramShow';
import ScheduledEventPicker from './components/ScheduledEventPicker';
import CheckoutCountdown from './components/CheckoutCountdown';
import ConfirmCancelModal from './components/ConfirmCancelModal';
import Loader from './components/Loader';
import './style.scss';

const SchedulingWidget = () => {
  usePopupCheckoutCallbacks();
  useScrollBlocker();

  return (
    <>
      <ConfirmCancelModal />
      <TriggerElement />
      <CheckoutCountdown />
      <SchedulingModal>
        <>
          <Loader />
          <PdsBox fit={true}>
            <PdsRow>
              <PdsBox sizeMd="4" direction="column" align-items="flex-start">
                <CoachingProgramShow />
              </PdsBox>
              <PdsBox sizeMd="8" direction="column">
                <ScheduledEventPicker />
              </PdsBox>
            </PdsRow>
          </PdsBox>
        </>
      </SchedulingModal>
    </>
  );
};

export default SchedulingWidget;
