import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';

import ErrorBoundary from 'common/components/ErrorBoundary';

import { PropsProvider, UiResourceProvider, ToastContextProvider } from './contexts';
import SchedulingWidget from './SchedulingWidget';
import { EntrypointProps } from './SchedulingWidget.types';
import QueryProvider from './QueryClientProvider';

const SchedulingWidgetContainer = (props: EntrypointProps) => (
  <ErrorBoundary fallbackRender={fallbackRender(props)}>
    <PropsProvider value={props}>
      <ToastContextProvider>
        <QueryProvider>
          <UiResourceProvider>
            <>
              <ReactQueryDevtools />
              <SchedulingWidget />
            </>
          </UiResourceProvider>
        </QueryProvider>
      </ToastContextProvider>
    </PropsProvider>
  </ErrorBoundary>
);

const fallbackRender =
  ({ trigger }: EntrypointProps) =>
  ({ error }: { error: any }) => {
    console.log('ERROR Logger placeholder', error);
    // TODO: Fallback render
  };

export default SchedulingWidgetContainer;
