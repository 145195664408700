import { useEffect } from 'react';
import { useUiResourceContext } from '../contexts';

const CLASS_NAME = 'coaching-scheduling-widget--scroll-block';

const useScrollBlocker = () => {
  const { pickerOpen, checkoutCancelled, checkoutPending } = useUiResourceContext();

  useEffect(() => {
    const htmlElement = document.documentElement;

    if (pickerOpen || checkoutCancelled || checkoutPending) {
      htmlElement.classList.add(CLASS_NAME);
    } else {
      htmlElement.classList.remove(CLASS_NAME);
    }

    return () => {
      htmlElement.classList.remove(CLASS_NAME);
    };
  }, [pickerOpen, checkoutCancelled, checkoutPending]);
};

export default useScrollBlocker;
