import { useEffect } from 'react';

import {
  usePropsContext,
  useUiResourceContext,
} from 'apps/website/coaching/scheduling_widget/contexts';

const POPUP_CHECKOUT_OPEN_EVENT = 'kjb:popupCheckout:onOpen';
const POPUP_CHECKOUT_CLOSE_EVENT = 'kjb:popupCheckout:onClose';

interface PopupCheckoutEvent extends CustomEvent<{ offerToken: string }> {}

const useEventListener = ({
  eventName,
  active,
  callback,
}: {
  eventName: string;
  active: boolean;
  callback: (event: PopupCheckoutEvent) => void;
}) => {
  useEffect(() => {
    const eventHandler = (event: Event) => callback(event as PopupCheckoutEvent);
    if (active) {
      document.addEventListener(eventName, eventHandler);
    } else {
      document.removeEventListener(eventName, eventHandler);
    }
    return () => document.removeEventListener(eventName, eventHandler);
  }, [eventName, active, callback]);
};

//  usePopupCheckoutCallbacks
//  Listens for popup checkout callback events and updates the UiResourceContext.
const usePopupCheckoutCallbacks = () => {
  const { offerToken } = usePropsContext();
  const {
    setPickerOpen,
    checkoutPending,
    checkoutCancelled,
    setCheckoutPending,
    setCheckoutCancelled,
  } = useUiResourceContext();

  useEventListener({
    eventName: POPUP_CHECKOUT_OPEN_EVENT,
    active: checkoutPending || checkoutCancelled,
    callback: ({ detail: { offerToken: eventOfferToken } }) => {
      if (eventOfferToken === offerToken) {
        setPickerOpen(false);
        setCheckoutPending(true);
        setCheckoutCancelled(false);
      }
    },
  });

  useEventListener({
    eventName: POPUP_CHECKOUT_CLOSE_EVENT,
    active: checkoutPending || checkoutCancelled,
    callback: ({ detail: { offerToken: eventOfferToken } }) => {
      if (eventOfferToken === offerToken) {
        setPickerOpen(false);
        setCheckoutPending(false);
        setCheckoutCancelled(true);
      }
    },
  });
};

export default usePopupCheckoutCallbacks;
