import React, { useCallback } from 'react';
import { Modal } from '@kajabi/sage-react';
import { PdsIcon, PdsText } from '@pine-ds/react';
import { remove } from '@pine-ds/icons/icons';

import { useUiResourceContext } from 'apps/website/coaching/scheduling_widget/contexts';

const SchedulingModal = ({ children }: { children: React.ReactElement }) => {
  const { pickerOpen, setPickerOpen } = useUiResourceContext();

  const handleClose = useCallback(() => setPickerOpen(false), [setPickerOpen]);

  if (!pickerOpen) return null;

  return (
    <Modal
      active={true}
      onExit={handleClose}
      className="coaching-scheduling-widget__scheduling-modal"
    >
      <Modal.Header
        title={<PdsText tag="h5">Select a date and time</PdsText>}
        aside={<PdsIcon icon={remove} onClick={handleClose} />}
      />
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default SchedulingModal;
