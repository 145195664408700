import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useToastContext } from './contexts';

interface ErrorResponse {
  response: {
    data: {
      error_message: string; // eslint-disable-line camelcase
    };
  };
}

export default ({ children }: { children: JSX.Element }) => {
  const { showToast } = useToastContext();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        onError: (error: any) => {
          const {
            response: {
              data: { error_message: errorMessage = 'An error occurred' },
            },
          } = error as unknown as ErrorResponse;
          showToast({ title: errorMessage });
        },
      },
      mutations: {
        onError: (error: any) => {
          const {
            response: {
              data: { error_message: errorMessage = 'An error occurred' },
            },
          } = error as unknown as ErrorResponse;
          showToast({ title: errorMessage });
        },
      },
    },
  });

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
