import React from 'react';
import {
  usePropsContext,
  useUiResourceContext,
} from 'apps/website/coaching/scheduling_widget/contexts';

import ConfirmCancelModal from './ConfirmCancelModal';

const ConfirmCancelModalContainer = () => {
  const { offerToken } = usePropsContext();
  const { checkoutCancelled } = useUiResourceContext();

  if (!offerToken) return null;
  if (!checkoutCancelled) return null;

  return <ConfirmCancelModal />;
};

export default ConfirmCancelModalContainer;
