import React, { useRef } from 'react';
import { UiResourceContextInterface } from '../SchedulingWidget.types';
import { setDeepState } from './helpers/setDeepState';

/**
 * Context for managing the UI state of the scheduling widget
 * - pickerOpen             : Scheduling widget is open
 * - checkoutPending        : Popup checkout has been opened
 * - checkoutCancelled      : Popup checkout was dismissed prior to completion
 */

const noop = () => {};
const DEFAULTS = {
  pickerOpen: false,
  setPickerOpen: noop,
  checkoutPending: false,
  setCheckoutPending: noop,
  checkoutCancelled: false,
  setCheckoutCancelled: noop,
  reset: noop,
} as const;

export const UiResourceContext = React.createContext<UiResourceContextInterface>(DEFAULTS);

export const useUiResourceContext = () => {
  const context = React.useContext(UiResourceContext);
  if (!context) throw new Error(`outside of provider`);
  return context;
};

export const UiResourceProvider = ({ children }: { children: React.ReactElement }) => {
  const providerStateRef = useRef<UiResourceContextInterface>(DEFAULTS);
  let setProviderStateFn: React.Dispatch<React.SetStateAction<UiResourceContextInterface>>;

  const providerActions: Partial<UiResourceContextInterface> = {
    setPickerOpen: (active) => setDeepState(setProviderStateFn, ['pickerOpen'], active),
    setCheckoutPending: (active) => setDeepState(setProviderStateFn, ['checkoutPending'], active),
    setCheckoutCancelled: (active) =>
      setDeepState(setProviderStateFn, ['checkoutCancelled'], active),
    reset: () => {
      setDeepState(setProviderStateFn, ['pickerOpen'], DEFAULTS.pickerOpen);
      setDeepState(setProviderStateFn, ['checkoutPending'], DEFAULTS.checkoutPending);
      setDeepState(setProviderStateFn, ['checkoutCancelled'], DEFAULTS.checkoutCancelled);
    },
  };

  const initialState = Object.assign({}, DEFAULTS, providerActions);
  const [providerState, setProviderState] =
    React.useState<UiResourceContextInterface>(initialState);
  providerStateRef.current = providerState;
  setProviderStateFn = setProviderState;

  return <UiResourceContext.Provider value={providerState}>{children}</UiResourceContext.Provider>;
};
