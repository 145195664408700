import buildPopupCheckoutLinkData from './buildPopupCheckoutLinkData';

interface PopupCheckoutEvent extends CustomEvent<{ offerToken: string }> {}

function launchPopupCheckout({
  externalReferenceId,
  offerToken,
}: {
  externalReferenceId: number | string;
  offerToken: string;
}) {
  const {
    link: existingLink,
    href,
    popupCheckoutPayload,
  } = buildPopupCheckoutLinkData({
    externalReferenceId,
    offerToken,
  });

  if (existingLink instanceof HTMLAnchorElement) {
    existingLink.click();
    return;
  }

  // Append the new link to the page
  const link = document.createElement('a');
  link.innerHTML = 'Click me';
  link.href = href;
  link.style.cssText = 'display: none;';
  link.dataset.popupCheckout = popupCheckoutPayload;
  document.body.appendChild(link);

  // Bind a listener that will click the popup checkout link once ready
  document.addEventListener(
    'kjb:popupCheckout:onInit',
    (event: Event) => {
      const popupCheckoutEvent = event as PopupCheckoutEvent;
      if (popupCheckoutEvent.detail.offerToken === offerToken) {
        const mountedPopupCheckoutLink = document.querySelector(
          `[href="${href}"][data-popup-checkout]`,
        ) as HTMLElement;
        mountedPopupCheckoutLink.click();
      }
    },
    {
      once: true,
    },
  );

  // Dispatch an event to trigger popup checkout to mount on the newly appended link
  document.dispatchEvent(new CustomEvent('kjb:popupCheckout:init', { detail: { link } }));
}

export default launchPopupCheckout;
