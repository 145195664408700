import {
  SchedulingWidgetConfigResponse,
  CronofyOnSuccessResponse,
} from 'apps/website/coaching/scheduling_widget/SchedulingWidget.types';

const buildCronofyPickerSettings = ({
  widgetConfig,
  targetId,
  onSuccess,
}: {
  widgetConfig: SchedulingWidgetConfigResponse;
  targetId: string;
  onSuccess: (response: CronofyOnSuccessResponse) => void;
}) => {
  const { attributes } = widgetConfig.cronofySchedulingConfigurations[0];

  return {
    element_token: widgetConfig.cronofyElementToken,
    target_id: targetId,
    availability_query: {
      participants: [
        {
          members: [
            {
              sub: attributes.cronofyAccountIdentifier,
              availability_rule_ids: [attributes.cronofyAvailabilityRuleIdentifier],
              managed_availability: true,
            },
          ],
          required: 'all',
        },
      ],
      required_duration: { minutes: widgetConfig.coachingProgramDuration },
      query_periods: [
        {
          start: attributes.availabilityWindowStartTime,
          end: attributes.availabilityWindowEndTime,
        },
      ],
      start_interval: { minutes: attributes.startTimeIncrementsInMinutes },
      buffer: {
        before: { minutes: attributes.bookingTimeBufferBeforeInMinutes },
        after: { minutes: attributes.bookingTimeBufferAfterInMinutes },
      },
    },
    locale: attributes.locale,
    styles: {
      prefix: 'kajabi-calendar',
      colors: {
        buttonActive: 'black',
        buttonActiveText: 'white',
        buttonConfirm: 'black',
        buttonConfirmText: 'white',
        buttonHover: 'black',
        buttonTextHover: 'white',
        neutral: '#bbbab9', // --pine-color-grey-400
        neutralDark: '#bbbab9', // --pine-color-grey-400
      },
    },
    config: {
      display_slot_navigation: true,
    },
    callback: onSuccess,
  };
};

export default buildCronofyPickerSettings;
