import React from 'react';
import { PropsContextInterface } from '../SchedulingWidget.types';

const DEFAULTS = {
  trigger: null,
  triggerMountNode: null,
  coachingProgramId: null,
  configRoute: null,
  createSchedulingHoldRoute: null,
  destroySchedulingHoldRoute: null,
  offerToken: null,
  previewing: false,
} as const;

export const PropsContext = React.createContext<Required<PropsContextInterface>>(DEFAULTS);

export const usePropsContext = () => {
  const context = React.useContext(PropsContext);
  if (!context) throw new Error(`outside of provider`);
  return context;
};

export const PropsProvider = ({
  children,
  value,
}: {
  children: React.ReactElement;
  value: PropsContextInterface;
}) => {
  const providerValue = Object.assign({}, DEFAULTS, value);
  return <PropsContext.Provider value={providerValue}>{children}</PropsContext.Provider>;
};
