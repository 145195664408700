import createAuthAxios from 'common/lib/createAuthAxios';

import { CreateSchedulingHoldResponse } from 'apps/website/coaching/scheduling_widget/SchedulingWidget.types';

const createSchedulingHold = async ({
  coachingProgramId,
  url,
  cronofySchedulingConfigurationId,
  start,
  end,
  timezone,
}: {
  coachingProgramId: number;
  url: string;
  cronofySchedulingConfigurationId: number;
  start: string;
  end: string;
  timezone: string;
}): Promise<CreateSchedulingHoldResponse> => {
  const authAxios = createAuthAxios();

  const { data } = await authAxios.post<CreateSchedulingHoldResponse>(url, {
    coaching_program_id: coachingProgramId,
    cronofy_scheduling_configuration_id: cronofySchedulingConfigurationId,
    start_time: start,
    end_time: end,
    timezone,
  });

  return data;
};

export default createSchedulingHold;
