import React from 'react';
import { PdsBox, PdsText, PdsIcon } from '@pine-ds/react';
import { SageClassnames } from '@kajabi/sage-react';

import useSchedulingWidgetConfig from 'apps/website/coaching/scheduling_widget/hooks/useSchedulingWidgetConfigQuery';

const Property = ({ icon, value }: { icon: string; value: string }) => (
  <div>
    <PdsBox gap="xs" align-items="center">
      <PdsIcon icon={icon} />
      <PdsText tag="p" weight="medium">
        {value}
      </PdsText>
    </PdsBox>
  </div>
);

const CoachingProgramShow = () => {
  const { data: config, isLoading } = useSchedulingWidgetConfig();

  if (!config) return null;
  if (isLoading) return null;

  const displayedLocation =
    config.coachingProgramLocation === 'live_video' ? 'Live Video' : 'Custom';

  return (
    <>
      <PdsText tag="h3" gutter="xs">
        {config.coachingProgramTitle}
      </PdsText>
      <Property icon="clock" value={`${config.coachingProgramDuration} minutes`} />
      <Property icon="video-on" value={displayedLocation} />
      <PdsText className={SageClassnames.SPACERS.MD_TOP} tag="p" gutter="xl">
        {config.coachingProgramDescription || 'Description not provided'}
      </PdsText>
    </>
  );
};

export default CoachingProgramShow;
