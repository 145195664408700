import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

/*
setDeepState
  Calls lodash.set with the `path` of the provider state to update
  Ensures the new state is a copy and does not contain references
*/
export const setDeepState = (
  setState: React.Dispatch<React.SetStateAction<any>>,
  path: (string | number)[],
  value: any,
) => setState((prevState: Object) => set(Object.create(prevState), path, cloneDeep(value)));
