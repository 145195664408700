import React from 'react';
import ReactDOM from 'react-dom';

import {
  usePropsContext,
  useUiResourceContext,
} from 'apps/website/coaching/scheduling_widget/contexts';

import reactElementFromHtmlElement from 'apps/website/coaching/scheduling_widget/utils/reactElementFromHtmlElement';

const TriggerElement = () => {
  const { trigger, triggerMountNode } = usePropsContext();
  const { pickerOpen, checkoutPending, checkoutCancelled, setPickerOpen } = useUiResourceContext();
  const isDisabled = pickerOpen || checkoutPending || checkoutCancelled; // button is disabled when the modal is open

  const handleClick = () => {
    if (isDisabled) return;
    setPickerOpen(true);
  };

  if (!(trigger instanceof HTMLElement)) return null;
  if (!(triggerMountNode instanceof HTMLElement)) return null;

  return ReactDOM.createPortal(
    React.cloneElement(reactElementFromHtmlElement(trigger), {
      onClick: handleClick,
      disabled: isDisabled,
    }),
    triggerMountNode,
  );
};

export default TriggerElement;
