import createAuthAxios from 'common/lib/createAuthAxios';

import { SchedulingWidgetConfigResponse } from 'apps/website/coaching/scheduling_widget/SchedulingWidget.types';

const fetchSchedulingWidgetConfig = async ({
  url,
  previewing,
}: {
  url: string;
  previewing: boolean;
}): Promise<Array<SchedulingWidgetConfigResponse>> => {
  const authorizedAxios = createAuthAxios();

  const { data } = await authorizedAxios.get(url, {
    params: {
      previewing,
    },
  });

  return data;
};

export default fetchSchedulingWidgetConfig;
