import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { Toast } from '@kajabi/sage-react';
import { setDeepState } from './helpers/setDeepState';

export interface ToastContextInterface {
  title: string | null;
  type: 'default' | 'danger' | 'loading' | null;
  timeout: number | null;
  icon: string | null;
  isActive: boolean;
  showToast: (
    config: Partial<Pick<ToastContextInterface, 'title' | 'type' | 'timeout' | 'icon'>>,
  ) => void;
  hideToast: () => void;
}

const noop = () => {};
const DEFAULTS = {
  title: null,
  type: null,
  timeout: null,
  icon: null,
  isActive: false,
  showToast: noop,
  hideToast: noop,
} as const;

const SageToast = () => {
  const {
    hideToast,
    title,
    type = 'danger',
    timeout = 3500,
    icon = 'danger-filled',
    isActive,
  } = useToastContext();

  return ReactDOM.createPortal(
    <Toast
      onDismiss={hideToast}
      title={title}
      type={type}
      timeout={timeout}
      icon={icon}
      isActive={isActive}
    />,
    document.body,
  );
};

export const ToastContext = React.createContext<ToastContextInterface>(DEFAULTS);

export const useToastContext = () => {
  const context = React.useContext(ToastContext);
  if (!context) throw new Error(`outside of provider`);
  return context;
};

export const ToastContextProvider = ({ children }: { children: React.ReactElement }) => {
  const providerStateRef = useRef<ToastContextInterface>(DEFAULTS);
  let setProviderStateFn: React.Dispatch<React.SetStateAction<ToastContextInterface>>;

  const providerActions: Partial<ToastContextInterface> = {
    showToast: (config) => {
      setDeepState(setProviderStateFn, ['isActive'], true);
      setDeepState(setProviderStateFn, ['title'], config.title);
      setDeepState(setProviderStateFn, ['type'], config.type);
      setDeepState(setProviderStateFn, ['timeout'], config.timeout);
      setDeepState(setProviderStateFn, ['icon'], config.icon);
    },
    hideToast: () => {
      setDeepState(setProviderStateFn, ['isActive'], false);
      setDeepState(setProviderStateFn, ['title'], DEFAULTS.title);
      setDeepState(setProviderStateFn, ['type'], DEFAULTS.type);
      setDeepState(setProviderStateFn, ['timeout'], DEFAULTS.timeout);
      setDeepState(setProviderStateFn, ['icon'], DEFAULTS.icon);
    },
  };

  const initialState = Object.assign({}, DEFAULTS, providerActions);
  const [providerState, setProviderState] = React.useState<ToastContextInterface>(initialState);
  providerStateRef.current = providerState;
  setProviderStateFn = setProviderState;

  return (
    <ToastContext.Provider value={providerState}>
      {children}
      <SageToast />
    </ToastContext.Provider>
  );
};
