import React, { useCallback, useEffect, useState } from 'react';
import { DateTimePicker } from 'cronofy-elements';
import { Loader } from '@kajabi/sage-react';

import {
  CronofyOnSuccessResponse,
  CronofySlotSelectedResponse,
} from 'apps/website/coaching/scheduling_widget/SchedulingWidget.types';

import {
  useUiResourceContext,
  usePropsContext,
} from 'apps/website/coaching/scheduling_widget/contexts';
import useSchedulingWidgetConfig from 'apps/website/coaching/scheduling_widget/hooks/useSchedulingWidgetConfigQuery';
import launchPopupCheckout from 'apps/website/coaching/scheduling_widget/utils/launchPopupCheckout';
import useSchedulingHoldCreate from 'apps/website/coaching/scheduling_widget/hooks/useSchedulingHoldCreateQuery';

import buildCronofyPickerSettings from './buildCronofyPickerSettings';

const CRONOFY_ELEMENT_DOM_ID = 'cronofy-date-time-picker';

const ScheduledEventPicker = () => {
  const { offerToken } = usePropsContext();
  const { setCheckoutPending, checkoutPending } = useUiResourceContext();
  const { data: config, isLoading } = useSchedulingWidgetConfig();
  const schedulingHoldCreate = useSchedulingHoldCreate();
  const [cronofyPicker, setCronofyPicker] = useState(null);

  const onSuccess = useCallback(
    (response: CronofyOnSuccessResponse) => {
      // Check if the response is a slot_selected response type
      // and then typecast CronofySlotSelectedResponse to response,
      // otherwise do nothing
      if (!(response.notification.type === 'slot_selected')) return;

      schedulingHoldCreate.mutate(response as CronofySlotSelectedResponse, {
        onSuccess: (response) => {
          if (!response) return;
          if (!offerToken) return;
          setCheckoutPending(true);
          launchPopupCheckout({
            externalReferenceId: response.data.attributes.id,
            offerToken,
          });
        },
      });
    },
    [schedulingHoldCreate, setCheckoutPending, offerToken],
  );

  useEffect(() => {
    if (!config) return;
    if (cronofyPicker) return;
    setCronofyPicker(
      DateTimePicker(
        buildCronofyPickerSettings({
          targetId: CRONOFY_ELEMENT_DOM_ID,
          widgetConfig: config,
          onSuccess,
        }),
      ),
    );
  }, [setCronofyPicker, cronofyPicker, config, onSuccess]);

  if (checkoutPending) return null;
  if (!config) return null;
  if (isLoading) return null;

  return (
    <div className="coaching-scheduling-widget__picker-wrapper">
      <div id={CRONOFY_ELEMENT_DOM_ID} />
    </div>
  );
};

export default ScheduledEventPicker;
