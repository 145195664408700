import React, { useEffect } from 'react';
import { Modal } from '@kajabi/sage-react';
import { PdsButton, PdsText } from '@pine-ds/react';
import { useQueryClient } from 'react-query';

import {
  usePropsContext,
  useUiResourceContext,
  useToastContext,
} from 'apps/website/coaching/scheduling_widget/contexts';
import launchPopupCheckout from 'apps/website/coaching/scheduling_widget/utils/launchPopupCheckout';
import removePopupCheckout from 'apps/website/coaching/scheduling_widget/utils/removePopupCheckout';
import useDestroySchedulingHold from 'apps/website/coaching/scheduling_widget/hooks/useDestroySchedulingHoldQuery';
import queryClientCache from 'apps/website/coaching/scheduling_widget/utils/queryClientCache';

const ConfirmCancelModal = () => {
  const { offerToken } = usePropsContext();
  const { reset } = useUiResourceContext();
  const { showToast } = useToastContext();
  const queryClient = useQueryClient();
  const deleteSchedulingHold = useDestroySchedulingHold();

  useEffect(() => {
    const schedulingHoldId = queryClientCache.schedulingHold.getLast(queryClient)?.id;
    if (!schedulingHoldId) reset();
  }, []);

  const handleContinue = () => {
    if (!offerToken) return;
    const schedulingHoldId = queryClientCache.schedulingHold.getLast(queryClient)?.id;
    if (schedulingHoldId) {
      launchPopupCheckout({ externalReferenceId: schedulingHoldId, offerToken });
    } else {
      showToast({ title: 'The reservation has expired' });
      reset();
    }
  };

  const handleCancel = () => {
    if (!offerToken) return;
    const schedulingHoldId = queryClientCache.schedulingHold.getLast(queryClient)?.id;
    if (schedulingHoldId) {
      deleteSchedulingHold.mutate(schedulingHoldId);
      removePopupCheckout({ externalReferenceId: schedulingHoldId, offerToken });
    }
    reset();
  };

  return (
    <Modal
      active={true}
      onExit={() => {}}
      className="coaching-scheduling-widget__confirm-cancel-modal"
    >
      <Modal.Header title={<PdsText tag="h5">Cancel reservation</PdsText>} />
      <Modal.Body>
        <PdsText tag="p">
          Would you like to cancel your reservation for this time or continue booking?
        </PdsText>
      </Modal.Body>
      <Modal.Footer>
        <PdsButton role="button" variant="secondary" onClick={handleCancel}>
          Cancel reservation
        </PdsButton>
        <PdsButton role="button" onClick={handleContinue}>
          Continue booking
        </PdsButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmCancelModal;
