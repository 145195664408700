import React from 'react';
import { useUiResourceContext } from 'apps/website/coaching/scheduling_widget/contexts';
import CheckoutCountdown from './CheckoutCountdown';

const CheckoutCountdownContainer = () => {
  const { checkoutPending, checkoutCancelled } = useUiResourceContext();

  if (checkoutCancelled || checkoutPending) {
    return <CheckoutCountdown />;
  }

  return null;
};

export default CheckoutCountdownContainer;
