import { useMutation, useQueryClient } from 'react-query';
import { usePropsContext } from 'apps/website/coaching/scheduling_widget/contexts';
import queryClientCache from 'apps/website/coaching/scheduling_widget/utils/queryClientCache';
import deleteSchedulingHold from '../api/deleteSchedulingHold';
import * as QUERY_KEYS from './query-keys';

function useDestroySchedulingHold() {
  const queryClient = useQueryClient();
  const { destroySchedulingHoldRoute } = usePropsContext();

  return useMutation(
    async (holdId: number) => {
      if (!destroySchedulingHoldRoute) {
        return Promise.resolve(); // no-op
      }

      return deleteSchedulingHold({
        holdId,
        url: destroySchedulingHoldRoute,
      });
    },
    {
      onSuccess: (_, holdId) => {
        queryClientCache.schedulingHold.delete(queryClient, holdId);
        queryClient.invalidateQueries(QUERY_KEYS.CREATE_SCHEDULING_HOLD);
      },
    },
  );
}

export default useDestroySchedulingHold;
