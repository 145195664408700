function humanizeCountdown(seconds: number) {
  if (seconds === 1) {
    return `${seconds} second`;
  }
  if (seconds < 60) {
    return `${seconds} seconds`;
  }
  const minutes = Math.floor(seconds / 60);
  const minuteSeconds = seconds % 60;
  return `${minutes}:${minuteSeconds < 10 ? `0${minuteSeconds}` : minuteSeconds}`;
}

export default humanizeCountdown;
