import React from 'react';
import { PdsBox, PdsText } from '@pine-ds/react';
import { Loader as SageLoader } from '@kajabi/sage-react';

import useSchedulingWidgetConfig from 'apps/website/coaching/scheduling_widget/hooks/useSchedulingWidgetConfigQuery';

const Loader = () => {
  const { isLoading } = useSchedulingWidgetConfig();

  if (!isLoading) return null;

  return (
    <PdsBox direction="column" display="block" padding="xl">
      <PdsText tag="h5" gutter="sm" align="center" color="secondary">
        Loading...
      </PdsText>
      <SageLoader loading={true} fillSpace={true} />
    </PdsBox>
  );
};

export default Loader;
