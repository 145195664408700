import React from 'react';
import ReactDom from 'react-dom';
import * as Sentry from '@sentry/react';
import { initializeSentry } from 'common/components/Sentry/Sentry';
import { SageBreakpointProvider, SageTokens } from '@kajabi/sage-react';

import { SchedulingWidget } from 'apps/website/coaching/scheduling_widget';
import fragmentFromString from 'apps/website/coaching/scheduling_widget/utils/fragmentFromString';

document.addEventListener('DOMContentLoaded', () => {
  const rootNodes = document.querySelectorAll('[id^="coaching-scheduling-widget-react-root"]');

  initializeSentry({
    tags: { team: 'KnowledgeProducts', component: 'website_coaching_scheduling_widgets' },
  });

  rootNodes.forEach((mountNode) => {
    if (!(mountNode instanceof HTMLElement)) return;

    document.body.classList.add('sage-excluded');

    const {
      configRoute,
      createSchedulingHoldRoute,
      destroySchedulingHoldRoute,
      offerToken,
      coachingProgramId,
      previewing,
    } = mountNode.dataset;

    if (!configRoute) return;
    if (!createSchedulingHoldRoute) return;
    if (!destroySchedulingHoldRoute) return;
    if (!offerToken) return;
    if (!coachingProgramId) return;

    // Store the CTA rendered by the theme block
    const ctaButton = mountNode.parentElement?.querySelector(
      '.coaching-scheduling-widget-cta-button',
    );
    if (!(ctaButton instanceof HTMLElement)) return;
    if (!ctaButton.parentNode) return;

    // Create a mount node adjacent to the stored CTA
    const ctaMountNodeId = `coaching-scheduling-widget-react-trigger-${crypto.randomUUID()}`;
    ctaButton.parentNode.insertBefore(
      fragmentFromString(`<div id="${ctaMountNodeId}"></div>`),
      ctaButton,
    );
    const ctaMountNode = document.getElementById(ctaMountNodeId);
    if (!(ctaMountNode instanceof HTMLElement)) return;

    // Remove the CTA rendered by the theme block
    ctaButton.remove();

    ReactDom.render(
      <Sentry.ErrorBoundary>
        <SageBreakpointProvider queries={SageTokens.BREAKPOINT_QUERIES}>
          <SchedulingWidget
            createSchedulingHoldRoute={createSchedulingHoldRoute}
            destroySchedulingHoldRoute={destroySchedulingHoldRoute}
            configRoute={configRoute}
            coachingProgramId={Number(coachingProgramId)}
            offerToken={offerToken}
            previewing={previewing === 'true'}
            trigger={ctaButton}
            triggerMountNode={ctaMountNode}
          />
        </SageBreakpointProvider>
      </Sentry.ErrorBoundary>,
      mountNode,
    );
  });
});
