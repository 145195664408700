const OFFER_CHECKOUT_EXTERNAL_REFERENCE_INITIATOR = 'Coaching::SchedulingHold';

const buildPopupCheckoutLinkData = ({
  externalReferenceId,
  offerToken,
}: {
  externalReferenceId: number | string;
  offerToken: string;
}) => {
  const href = `#popup_checkout_${offerToken}`;
  const popupCheckoutPayload = btoa(
    JSON.stringify({
      externalReference: {
        initiator: OFFER_CHECKOUT_EXTERNAL_REFERENCE_INITIATOR,
        id: String(externalReferenceId),
      },
    }),
  );
  const link = document.querySelector(
    `[href="${href}"][data-popup-checkout="${popupCheckoutPayload}"]`,
  );
  return { link, href, popupCheckoutPayload };
};

export default buildPopupCheckoutLinkData;
