import { useMutation, useQueryClient } from 'react-query';

import { usePropsContext } from 'apps/website/coaching/scheduling_widget/contexts';
import queryClientCache from 'apps/website/coaching/scheduling_widget/utils/queryClientCache';

import { CronofySlotSelectedResponse } from 'apps/website/coaching/scheduling_widget/SchedulingWidget.types';
import useSchedulingWidgetConfig from './useSchedulingWidgetConfigQuery';

import createSchedulingHold from '../api/createSchedulingHold';

function useSchedulingHoldCreate() {
  const { coachingProgramId, createSchedulingHoldRoute } = usePropsContext();
  const { data: config } = useSchedulingWidgetConfig();
  const queryClient = useQueryClient();

  return useMutation(
    async (selectedSlot: CronofySlotSelectedResponse) => {
      if (!coachingProgramId || !config || !createSchedulingHoldRoute) {
        return Promise.resolve(); // no-op
      }

      return createSchedulingHold({
        coachingProgramId,
        url: createSchedulingHoldRoute,
        cronofySchedulingConfigurationId: config.cronofySchedulingConfigurations[0].attributes.id,
        start: selectedSlot.notification.slot.start,
        end: selectedSlot.notification.slot.end,
        timezone: selectedSlot.notification.tzid,
      });
    },
    {
      onSuccess: (data) => {
        if (!data) return;
        queryClientCache.schedulingHold.store(queryClient, data);
      },
    },
  );
}

export default useSchedulingHoldCreate;
