import React, { useEffect, useState, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from '@kajabi/sage-react';
import { PdsTooltip, PdsIcon, PdsText, PdsButton } from '@pine-ds/react';
import { questionCircle, remove } from '@pine-ds/icons/icons';
import humanizeCountdown from 'apps/website/coaching/scheduling_widget/utils/humanizeCountdown';
import * as CONSTANTS from 'apps/website/coaching/scheduling_widget/constants';

const COUNTDOWN_WARNING_THRESHOLD = 1 * 60; // 1 minute

const CheckoutCountdown = () => {
  const [secondsLeft, setSecondsLeft] = useState(
    CONSTANTS.SCHEDULING_HOLD_EXPIRATION_MILLISECONDS / 1000,
  );
  const [warningModalDismissed, setWarningModalDismissed] = useState(false);
  const timerRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setSecondsLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current!);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  const timeRemainingText = useMemo(() => humanizeCountdown(secondsLeft), [secondsLeft]);

  const countdownEl = useMemo(() => {
    if (secondsLeft === 0) {
      return (
        <div className="coaching-scheduling-widget__countdown-container">
          <div className="coaching-scheduling-widget__countdown-text">Reservation expired</div>
          <PdsTooltip
            content="Other clients will be able to book this time."
            has-arrow="true"
            placement="bottom"
          >
            <PdsIcon icon={questionCircle} color="var(--pine-color-white)" size="medium" />
          </PdsTooltip>
        </div>
      );
    }

    return (
      <div className="coaching-scheduling-widget__countdown-container">
        <div className="coaching-scheduling-widget__countdown-text">
          Time remaining {timeRemainingText}
        </div>
        <PdsTooltip
          content="This timer shows how long we will hold this time slot for you. If the time runs out, other clients will be able to book this time, but you can always schedule your session after purchase."
          has-arrow="true"
          placement="bottom"
        >
          <PdsIcon icon={questionCircle} color="var(--pine-color-white)" size="medium" />
        </PdsTooltip>
      </div>
    );
  }, [secondsLeft, timeRemainingText]);

  const warningModalEl = useMemo(() => {
    if (warningModalDismissed) return <></>;
    if (secondsLeft > COUNTDOWN_WARNING_THRESHOLD) return <></>;

    const handleDismiss = () => setWarningModalDismissed(true);

    return (
      <Modal active={true} className="coaching-scheduling-widget__warning-modal">
        <Modal.Header
          title={<PdsText tag="h5">Still there?</PdsText>}
          aside={<PdsIcon icon={remove} onClick={handleDismiss} />}
        />
        <Modal.Body>
          <PdsText tag="p">
            Would you like to continue booking your session? If not, your time slot will be released
            and will start over in {timeRemainingText}.
          </PdsText>
        </Modal.Body>
        <Modal.Footer>
          <PdsButton onClick={handleDismiss}>Continue</PdsButton>
        </Modal.Footer>
      </Modal>
    );
  }, [secondsLeft, timeRemainingText, warningModalDismissed, setWarningModalDismissed]);

  return (
    <>
      {ReactDOM.createPortal(warningModalEl, document.body)}
      {ReactDOM.createPortal(countdownEl, document.body)}
    </>
  );
};

export default CheckoutCountdown;
