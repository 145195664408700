import buildPopupCheckoutLinkData from './buildPopupCheckoutLinkData';

function removePopupCheckout({
  externalReferenceId,
  offerToken,
}: {
  externalReferenceId: number | string;
  offerToken: string;
}) {
  const { link: existingLink } = buildPopupCheckoutLinkData({
    externalReferenceId,
    offerToken,
  });

  if (existingLink instanceof HTMLAnchorElement) {
    existingLink.remove();
  }
}

export default removePopupCheckout;
