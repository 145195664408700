import { QueryClient } from 'react-query';
import { CreateSchedulingHoldResponse } from 'apps/website/coaching/scheduling_widget/SchedulingWidget.types';
import * as QUERY_KEYS from 'apps/website/coaching/scheduling_widget/hooks/query-keys';
import * as CONSTANTS from 'apps/website/coaching/scheduling_widget/constants';

export default {
  schedulingHold: {
    store: (queryClient: QueryClient, data: CreateSchedulingHoldResponse) => {
      queryClient.setQueryDefaults(QUERY_KEYS.CREATE_SCHEDULING_HOLD, {
        cacheTime: CONSTANTS.SCHEDULING_HOLD_EXPIRATION_MILLISECONDS,
        staleTime: CONSTANTS.SCHEDULING_HOLD_EXPIRATION_MILLISECONDS,
      });
      queryClient.setQueryData<CreateSchedulingHoldResponse[]>(
        QUERY_KEYS.CREATE_SCHEDULING_HOLD,
        (prev = []) => [...prev, data],
      );
    },
    getLast: (queryClient: QueryClient) => {
      const schedulingHolds = queryClient.getQueryData<CreateSchedulingHoldResponse[]>(
        QUERY_KEYS.CREATE_SCHEDULING_HOLD,
      );

      return schedulingHolds?.at(-1)?.data.attributes;
    },
    delete: (queryClient: QueryClient, id: number) => {
      queryClient.setQueryData<CreateSchedulingHoldResponse[]>(
        QUERY_KEYS.CREATE_SCHEDULING_HOLD,
        (prev = []) => prev.filter((hold) => hold.data.attributes.id !== id),
      );
    },
  },
};
